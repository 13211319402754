import React from 'react'
import Article from '../../components/article/Article';
import blog01 from '../../assets/blog01.png';
import blog02 from '../../assets/blog02.png';
import blog03 from '../../assets/blog03.png';
import './cta.css'

export default function CTA() {
  return (
    <div className="gpt3__blog section__padding" id="blog">
      <div className="gpt3__blog-heading">
        <h1 className="">Founding Team</h1>
      </div>
      <div className="gpt3__blog-container">
        
        <div className="gpt3__blog-container_groupB">
          <a href="https://ece.nitk.ac.in/faculty/shyam-lal" style={{textDecoration:'none'}}><Article imgUrl={blog01} date="CEO and Director" text="Dr. Shyam Lal" hp = "https://ece.nitk.ac.in/faculty/shyam-lal" /></a>
          <a href="https://ece.nitk.ac.in/professor/sushil-kumar-pandey
" style={{textDecoration:'none'}}><Article imgUrl={blog03} date="CTO  and Director " text="Dr. Sushil  Kumar Pandey" hp="https://ece.nitk.ac.in/professor/sushil-kumar-pandey
" /></a>
        </div>
      </div>
    </div>
  )
}
