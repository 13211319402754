import React from 'react'
import { Feature } from '../components'
import { Footer } from '../containers'
import Navbar from './navbar/Navbar'

export default function Gaiic() {
  let univ = [
    "1. Two expert lectures series (12 hours interactive practical workshop) on the topic related to the Fundamentals and Applications of AI and ML.",
    "2. One expert lecture series (2 hours interactive workshop) for faculty members/PG Student) on “How to write good R&D project proposal”.",
    "3. One expert lecture series (2 hours interactive workshop) for faculty members and PG/Research students) on “How to write good research paper”.",
    "4.  Mentoring and support to the faculty members/Professors of university/college for Improving quality research publications.", 
    "5. Mentoring and support to the faculty members/Professors of university/college for Improving external funded R&D Project.", 
    "6. Company will be providing consent letter as Industry partner to the university/college while applying R&D Project proposal to the Govt. and other funding agencies. ",
    "7. 6 Weeks Summer/Winter research Internship for 04 students –nominated by the concerned academic organization.",
    "8. Letter of Recommendation for 04 interns for their Higher studies (subjective to performance during Internship)", 
    "9. AIQSS Excellence Awards for top three students (Gold, Silver and Bronze) passing out batch of Undergraduate."
  ]

  let medical = [
    "1. Two expert lectures series (12 hours interactive workshop) on the topic related to the Introduction and Applications of AI and ML in Radiology, Histopathology, Dermatology, Oncology, and Bio-signals. ",
    "2. One expert lecture series (3 hours interactive workshop for   faculty members and PG students) on “How to write good research paper in the area of healthcare applications”.",
    "3. One expert lecture series (3 hours interactive workshop for faculty members and PG/Research Students) on “How to write good R&D project proposal in the healthcare applications”.",
    "4. Mentoring and support to the faculty members/Professors of medical college/ school of medicine for Improving their research publications in the in the above-mentioned areas. ",
    "5. Mentoring and support to the faculty members/Professors of medical college/ school of medicine for Improving funded R&D Project in the above-mentioned areas. ",
    "6. Company will be providing consent letter as Industry partner to the medical college/ school of medicine while applying R&D Project proposal to the Govt. and other funding agencies. ",
    "7. AIQSS Excellence Awards for top three students (Gold, Silver and Bronze) passing out batch of Undergraduate/Postgraduate programs."
  ]
  return (
      <div className="gradient__bg">
          <Navbar />
          {/* <div className="gpt3__whatgpt3-heading"> */}
                <h1 style={{color:'#ffff', paddingLeft:'15px', paddingBottom:'20px'}}>Establishing  following  research centres  across the globe:</h1>
          {/* </div> */}
          <Feature title="" text="" />
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>AI Innovation Centre(AIIC) (University and Engineering Colleges in India and Abroad)</p>
          <Feature title="" text="" />
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>AI Innovation Centre  for Healthcare(AIICH) (Medical Colleges/School of Medicines in India and Abroad)</p>
          <Feature title="" text="" />
          <p style={{color:'#ffff', paddingLeft:'15px', fontSize:'20px', paddingBottom:'10px'}}>
          List of established AI Innovation Centre :
          <ul>
              <li>New Horizon College of Engineering, Bangalore, Karnataka, India.</li>
              <li>PBR VISVODAYA INSTITUTE OF TECHNOLGY AND SCIENCE, KAVALI, NELLORE DT, AP. India </li>
          </ul>
          </p>
          
        <Footer />
      </div>
      
  )
}
